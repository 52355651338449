const routes = {
  LOGIN: "/",
  FORGET_PASSWORD: "/forget-password",
  UPDATE_PASSWORD: "/update/password",
  COMPLETE_PROFILE: "/complete-profile",
  DASHBOARD: "/dashboard",
  ACCOUNT_INFO: "account",
  USERS: {
    INDEX: "users",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
  AGENCIES: {
    INDEX: "agencies",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
  SITE_VISITER: {
    INDEX: "pixel",
  },
  AUDIENCE_MANAGER: {
    INDEX: "audience-manager",
    VIEW: (id: string | number = ":id") => `view/${id}`,
    CREATE_SALESFORCE: "salesforce",
    CREATE_HUBSPOT: "hubspot",
  },
  INTEGRATIONS: "integrations",
  ADVERTISERS: {
    INDEX: "advertisers",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
  LINKEDIN_ENGAGEMANT: {
    INDEX: "social-engagement",
  },
  CAMPAIGNS: {
    INDEX: "campaigns",
    GENERAL_CAMPAIGN: {
      INDEX: "general-campaign",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `${id}`,
      VIEW: (id: string | number = ":id") => `view/${id}`,
      LINE_ITEMS: {
        INDEX: (id: string | number = ":id") => `${id}/lineitems`,
        EDIT: (id: string | number = ":lineItemId") => `edit/${id}`,
      },
    },
    QUICK_CAMPAIGN: {
      INDEX: "quick-campaign",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `${id}`,
    },
  },
  AUDIENCES: {
    INDEX: "audiences",
    ACCOUNT_AUDIENCE: {
      INDEX: "account-audiences",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
    EMAIL_AUDIENCES: {
      INDEX: "email-audiences",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
    RETARGETING: {
      INDEX: "retargeting",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
  },
  CONVERSION: {
    INDEX: "conversion",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  SALES_FORCE: {
    INDEX: "salesforce",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
  UTM: {
    INDEX: "utm",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
  ASSETS: {
    CREATIVES: {
      INDEX: "creatives",
      CREATE: "create",
      DETAIL: (id: string | number = ":id") => `detail/${id}`,
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
    TEMPLATE: {
      INDEX: "template",
      DETAIL: (id: string | number = ":id") => `detail/${id}`,
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
    LEADGEN: {
      INDEX: "leads",
      LINKEDIN: {
        INDEX: "linkedin",
      },
      DISPLAY: {
        INDEX: "display",
      },
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
  },
  REPORTS: {
    INDEX: "reports",
    STATS: {
      INDEX: "campaign-stats",
    },
    ABM: {
      INDEX: "abm",
      CAMPAIGN_SUMMARY: "campaign-summary",
      PAGE_ANALYTIC: "page-analytics",
      AD_METRIC: "ad-metrics",
      ENGAGEMENT_METRIC: "engagement-metrics",
      ACCOUNTS_VISITED: "accounts-visited",
      ACCOUNTS_REACHED: "accounts-reached",
      ACCOUNTS_ENGAGED: "accounts-engaged",
      UNIQUE_VISITORS: "unique-visitors",
      ENGAGED_VISITORS: "engaged-visitors",
      PAGE_VIEWS: "page-views",
      REPORTS: "downloads",
    },
    ATTRIBUTION: {
      INDEX: "attribution",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
    LEAD_APPEND: {
      INDEX: "intent-stream",
      DETAIL: (id: string | number = ":id") => `${id}`,
    },
    INTENTSTREAM: {
      INDEX: "intent-stream-beta",
      DETAIL: (id: string | number = ":id") => `${id}`,
    },
    REPORTS_SETUP: {
      INDEX: "setup",
      CREATE: "create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
  },
  VISITOR_INSIGHTS: {
    INDEX: "visitor-insights",
    COMPANY: {
      INDEX: "company",
      DETAIL: (id: string | number = ":id") => `${id}`,
    },
    PROFILE: {
      INDEX: "profile",
    },
    KEYWORD_SETTING: {
      INDEX: "keyword-settings",
    },
  },
  CONTACT: {
    INDEX: "contact",
  },
  PRESET: {
    INDEX: "preset",
    TARGETIN_EXPRESSION: {
      CREATE: "targeting-expression/create",
      EDIT: (id: string | number = ":id") => `edit/${id}`,
    },
  },
  CUSTOM_LISTS: {
    INDEX: "lists",
  },
  EMAIL_OUTREACH: {
    INDEX: "email-outreach",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
  },
} as const;

export default routes;
